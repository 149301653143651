import React from 'react';
import classnames from 'classnames';

import {
  section,
  article,
  description,
  speakerWrap,
  title,
  speakerInner,
  avatar,
  name,
  position,
  speakerContainer,
  largePadding,
  positionTop,
} from './styles.module.scss';

const Content = ({ data, disableForm }) => {
  const content = data?.webinarContentBody;

  const speakers = {
    title: data?.webinarContentSpeakersTitle,
    list: data?.webinarContentSpeakersList,
  };

  return (
    <section className={classnames(section, disableForm ? largePadding : null)}>
      <div className="scr-wrap">
        <article className={article}>
          <div
            className={description}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>

          <div
            className={classnames(
              speakerWrap,
              disableForm ? positionTop : null
            )}
          >
            <p className={classnames('scr-h5', title)}>{speakers?.title}</p>

            {speakers?.list?.map((item) => {
              return (
                <div className={speakerInner} key={item?.speakerName}>
                  <img
                    src={item?.speakerAvatar?.sourceUrl}
                    alt={item?.speakerName}
                    className={avatar}
                  />

                  <div className={speakerContainer}>
                    <p className={classnames('scr-lead', name)}>
                      {item?.speakerName}
                    </p>
                    <p className={classnames('scr-reg', position)}>
                      {item?.speakerPosition}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </article>
      </div>
    </section>
  );
};

export default Content;
