import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Layout from 'sections/Layout';
import Hero from 'sections/Webinar/Hero';
import Content from 'sections/Webinar/Content';
import CtaBox from 'sections/Webinar/CtaBox/index';

const WebinarPostPage = ({ webinar, ...props }) => {
  const seo = webinar.seo;
  const lang = webinar.language.slug;
  const hero = webinar.webinar.webinarHero;
  const form = webinar.webinar.webinarFormData;
  const content = webinar.webinar.webinarContent;
  const cta = webinar.webinar.webinarCtaBlock;
  const disableForm = webinar.webinar.webinarDisableForm;

  // useEffect(() => {
  //   if (!process.env.GATSBY_DEV) {
  //     typeof window !== 'undefined' &&
  //       window?.lintrk('track', { conversion_id: 14697825 });
  //   }
  // }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(
        'message',
        (e) => {
          if (e.origin !== 'https://app.livestorm.co') return;

          window?.dataLayer &&
            window.dataLayer.push({ event: 'webinar_thurgau_registration' });
        },
        false
      );
    }

    mixpanel.init('c83a628bacf3787c48030540be44ea19', {
      debug: false,
    });

    mixpanel.track_pageview({ page: hero.webinarHeroTitle });
  }, []);

  return (
    <Layout
      seo={seo}
      headerDarkColor={true}
      headerWhiteBg={true}
      requestButtonBlue={true}
      // ogImage={sourceUrl}
    >
      {(commonData) => (
        <div style={{ backgroundColor: '#F8FAFF' }}>
          <Hero data={hero} lang={lang} form={form} disableForm={disableForm} />
          <Content data={content} disableForm={disableForm} />
          {!disableForm ? (
            <CtaBox data={cta} disableForm={disableForm} />
          ) : (
            <></>
          )}
        </div>
      )}
    </Layout>
  );
};

export default WebinarPostPage;
