import React from 'react';
import classnames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { wrap, inner, title, text, button, img } from './styles.module.scss';

const CtaBox = ({ data }) => {
  return (
    <section className={wrap}>
      <div className="scr-wrap">
        <div className={inner}>
          <div className="">
            <p className={classnames('scr-h4', title)}>
              {data?.webinarCtaBlockTitle}
            </p>
            <p className={classnames('scr-lead', text)}>
              {data?.webinarCtaBlockSubtitle}
            </p>
          </div>

          <span id="anchor-link-to-webinar-form">
            <AnchorLink to={`#form`} className={button}>
              {data?.webinarCtaButtonTitle}
            </AnchorLink>
          </span>

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/05/Group-2688.svg"
            alt=""
            className={img}
            width="160"
            height="140"
          />
        </div>
      </div>
    </section>
  );
};

export default CtaBox;
