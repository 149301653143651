const webinarPost = (WpTag = '') => `
  databaseId
  date
  dateGmt
  id
  language {
    code
    locale
    name
    slug
  }
  link
  slug
  title
  uri
  seo {
    canonical
    cornerstone
    focuskw
    metaDesc
    metaKeywords
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphAuthor
    opengraphDescription
    opengraphModifiedTime
    opengraphPublishedTime
    opengraphPublisher
    opengraphSiteName
    opengraphTitle
    opengraphType
    opengraphUrl
    readingTime
    title
    twitterDescription
    twitterTitle
    opengraphImage {
      sourceUrl
    }
    twitterImage {
      sourceUrl
    }
    breadcrumbs {
      text
      url
    }
    schema {
      articleType
      pageType
    }
  }
  webinar {
    webinarHero {
      webinarHeroTitle
      webinarPreviewTimeNote
      webinarHeroTimeZoneNote
      webinarHeroTimeStart
      webinarHeroTimeEnd
      webinarHeroTags {
        webinarHeroTagItem
      }
      webinarHeroDescription
      webinarHeroDay
      webinarHeroDate
      webinarHeroBanner {
        altText
        sourceUrl
        srcSet
      }
    }
    webinarFormData {
      webinarFormEmbedUrl
      webinarFormSuccessTitle
      webinarFormSuccessDescription
      webinarFormSubmitButton
      webinarFormNote
      webinarFormLastNameLabel
      webinarFormFirsNameLabel
      webinarFormEmailLabel
      webinarFormCompanyLabel
      webinarFormCalendarLinkGoogle {
        title
        url
      }
      webinarFormCalendarFileIcs {
        altText
        sourceUrl
        link
      }
      webinarFormCalendarFileButtonTitle
    }
    webinarCtaBlock {
      webinarCtaButtonTitle
      webinarCtaBlockTitle
      webinarCtaBlockSubtitle
    }
    webinarContent {
      webinarContentSpeakersTitle
      webinarContentSpeakersList {
        speakerPosition
        speakerName
        speakerAvatar {
          altText
          sourceUrl
        }
      }
      webinarContentBody
    }
    fieldGroupName
    webinarDisableForm
    webinarHideOnIndex
  }
`;

module.exports.webinarPost = webinarPost;
