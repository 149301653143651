// extracted by mini-css-extract-plugin
export var description = "styles-module--description--1DAFA";
export var section = "styles-module--section--3_3vH";
export var largePadding = "styles-module--largePadding--rNeE9";
export var article = "styles-module--article--1AOCp";
export var speakerWrap = "styles-module--speakerWrap--2b-ZI";
export var positionTop = "styles-module--positionTop--3yI-z";
export var title = "styles-module--title--1_RYV";
export var speakerInner = "styles-module--speakerInner--MhCcd";
export var speakerContainer = "styles-module--speakerContainer--217Sd";
export var avatar = "styles-module--avatar--3ti3m";
export var name = "styles-module--name---kmXF";
export var position = "styles-module--position--6eFvR";
export var onDemandStyle = "styles-module--onDemandStyle--30Zjt";