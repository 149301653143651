// extracted by mini-css-extract-plugin
export var section = "styles-module--section--2Xt72";
export var banner = "styles-module--banner--3Esky";
export var inner = "styles-module--inner--2uJNl";
export var innerContent = "styles-module--innerContent--3OYSS";
export var formWrap = "styles-module--formWrap--2P_7a";
export var eventType = "styles-module--eventType--CMy63";
export var title = "styles-module--title--3A_Bg";
export var subTitle = "styles-module--subTitle--1O5RK";
export var divider = "styles-module--divider--2kNXw";
export var date = "styles-module--date--1jZyd";
export var time = "styles-module--time--3jlVc";