import React from 'react';

import Loader from 'components/Loader';
import ItemPost from 'templates/pages/WebinarPost';

import withPreview from '@/hoc/withPreview';
import { webinarPost } from 'constants/graphql/webinarPost';

const WebinarPost = ({ pageContext, loaded, ...props }) => {
  if (!loaded) return <Loader />;

  return <ItemPost {...pageContext} {...props} />;
};

const queryPreview = `
query WebinarPostQuery($id: ID = "") {
  webinar(id: $id, idType: DATABASE_ID, asPreview: true) {
    ${webinarPost()}
    revisions(first:1) {
      nodes {
        ${webinarPost()}
      }
    }
  }
}
`;

export default withPreview(WebinarPost, {
  queryPreview,
});
