import React from 'react';
import classnames from 'classnames';

// import WebinarForm from 'components/Forms/Webinar/index';

import {
  section,
  banner,
  inner,
  innerContent,
  formWrap,
  subTitle,
  eventType,
  title,
  date,
  time,
  divider,
} from './styles.module.scss';

import './styles.scss';

const Hero = ({ data, /*lang,*/ form, disableForm }) => {
  return (
    <section className={section} id="form">
      <picture>
        {/* <source
          srcSet="https://wp.screver.com/wp-content/uploads/2023/05/bg-10.webp"
          type="image/webp"
        />
        <source
          srcSet="https://wp.screver.com/wp-content/uploads/2023/05/bg-10.jpg"
          type="image/png"
        /> */}

        <img
          src={data?.webinarHeroBanner?.sourceUrl}
          srcSet={data?.webinarHeroBanner?.srcSet}
          alt=""
          className={banner}
        />
      </picture>

      <div className="scr-wrap">
        <div className={inner}>
          <div className={innerContent}>
            {data?.webinarHeroTags?.length > 0 ? (
              data?.webinarHeroTags.map((tag) => {
                return (
                  <span
                    key={tag?.webinarHeroTagItem}
                    className={classnames('scr-lead', eventType)}
                  >
                    {tag?.webinarHeroTagItem}
                  </span>
                );
              })
            ) : (
              <></>
            )}

            <h1 className={classnames(title)}>{data?.webinarHeroTitle}</h1>

            <p className={classnames(subTitle)}>
              {data?.webinarHeroDescription}
            </p>

            <div className={divider}></div>

            <p className={classnames('scr-h4', date)}>
              {data?.webinarHeroDate}, {data?.webinarHeroDay}
            </p>

            <p className={classnames('scr-lead', time)}>
              {data?.webinarHeroTimeStart} - {data?.webinarHeroTimeEnd}{' '}
              {data?.webinarHeroTimeZoneNote}
            </p>
          </div>

          {!disableForm ? (
            <div className={formWrap}>
              {/* <WebinarForm
                eventName={data?.webinarHeroTitle}
                data={form}
                lang={lang}
              /> */}

              <iframe
                title="Registration form"
                width="100%"
                height="600"
                src={form?.webinarFormEmbedUrl}
              ></iframe>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
